import { Link, useParams } from "react-router-dom";
import {
    LeftHeadDecoration,
    DetailRow, TxidLink
} from "../../../styles/main/MainCommonStyles";
import { DText, Flex, } from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import { formatDate, formatNumber } from "../../../core/utils/common";
import { TradeTypeBadge } from "../../../styles/main/orders";
import { getTradeTypeColor } from "../../../core/utils/theme";
import { useMainContext } from "../../../core/contexts/main";
import ModalLayout from "../../../components/layout/main/ModalLayout";
import Operation from "../../../components/common/utils/Operation";
import useBankTransactions from "../../../core/hooks/main/bank-transactions/useBankTransactions";
import { useGetABankTransaction } from "../../../core/services/react-query/report/banking";
import TransactionModal from "../../../components/modals/TransactionModal";
import DetailsLayout from "../../../components/layout/main/DetailsLayout";
import { BASE_URL } from "../../../core/constants/urls";



const BankTransactionDetails = () => {

    const params = useParams()
    const { lang } = useMainContext()

    const { data: transaction, isFetching, isError } = useGetABankTransaction(params.detailId)

    const {
        hasWriteAccess,
        onModalClose,
        onOptionClicked,
        modal,
        getTransactionOptions
    } = useBankTransactions()


    return (
        <DetailsLayout
            isFetching={isFetching}
            isError={isError}
            type={transaction?.status}
        >
            <LeftHeadDecoration left={'10px'}>
                <DText main>
                    {params.detailId}
                </DText>
            </LeftHeadDecoration>

            {/* content */}
            <Flex fw justify='flex-end'>
                <Operation
                    options={getTransactionOptions(transaction)}
                    hasWriteAccess={hasWriteAccess}
                    onOptionClicked={(idx) => onOptionClicked(idx, transaction)}
                />
            </Flex>

            <DetailRow>
                <DText main>
                    <Text tid={'bank-id'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary lineBreak={'anywhere'}>
                        {transaction?.account}
                    </DText>
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={'transaction-flow'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary>
                        <Text tid={transaction?.flow} />
                    </DText>
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={'transaction-status'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <TradeTypeBadge color={getTradeTypeColor(transaction?.status)}>
                        <Text tid={transaction?.status} />
                    </TradeTypeBadge>
                </Flex>
            </DetailRow>

            {transaction?.txId &&
                <DetailRow>
                    <DText main >
                        <Text tid={'txid'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <TxidLink
                            target='_blank'
                            href={`${BASE_URL}coining/txid/${transaction?.txId}?network=${transaction?.network}&symbol=${transaction?.coin}`}
                        >
                            {transaction?.txId}
                        </TxidLink>
                    </Flex>
                </DetailRow>
            }

            {transaction?.thirdParty &&
                <DetailRow>
                    <DText main>
                        <Text tid={'third-party'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <TradeTypeBadge color={getTradeTypeColor('limit')}>
                            <Text tid={transaction?.thirdParty?.name} />
                        </TradeTypeBadge>
                    </Flex>
                </DetailRow>
            }

            <DetailRow>
                <DText main>
                    <Text tid={'amount'} />
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary>
                        {formatNumber(transaction?.amount)}
                    </DText>
                </Flex>
            </DetailRow>
            <DetailRow>
                <DText main>
                    <Text tid={`pure-${transaction?.flow}`} />
                </DText>
                <Flex justify={'flex-end'}>
                    <DText primary>
                        {formatNumber(transaction?.totalAmount)}
                    </DText>
                </Flex>
            </DetailRow>

            {(transaction?.note && transaction.flow === 'withdraw') &&
                <DetailRow>
                    <DText main>
                        <Text tid={'note'} />
                    </DText>
                    <DText main style={{ textAlign: 'end' }}>
                        {transaction?.note}
                    </DText>

                </DetailRow>
            }
            {(transaction?.status === 'success' && transaction.flow === 'withdraw') &&
                <DetailRow>
                    <DText main>
                        <Text tid={'modified-at'} />
                    </DText>
                    <DText main style={{ textAlign: 'end' }}>
                        {formatDate(transaction?.modifiedAt, 'date', lang)}
                    </DText>

                </DetailRow>
            }
            {(transaction?.status === 'success' && transaction.flow === 'withdraw') &&
                <DetailRow>
                    <DText main>
                        <Text tid={'modified-by'} />
                    </DText>
                    <DText main style={{ textAlign: 'end', color: 'skyblue' }}>
                        <Link to={`/users/${transaction?.modifiedBy}`}>
                            {transaction?.modifiedBy}
                        </Link>
                    </DText>

                </DetailRow>
            }
            <Flex fw justify={'space-between'} style={{ marginTop: '20px' }}>
                <DText main>
                    {formatDate(transaction?.createdAt, 'date', lang)}
                </DText>
                <DText main>
                    {formatDate(transaction?.createdAt, 'time', lang)}
                </DText>
            </Flex>
            <ModalLayout
                open={modal.open}
                width={'520px'}
                onClose={onModalClose}
            >
                <TransactionModal
                    details={modal}
                    onClose={onModalClose}
                />
            </ModalLayout>
        </DetailsLayout>
    )
}


export default BankTransactionDetails
